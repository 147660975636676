import React from 'react';

const technologies = [
  {
    category: 'Frontend',
    skills: [
      { name: 'React', level: 'Advanced' },
      { name: 'JavaScript', level: 'Advanced' },
      { name: 'CSS', level: 'Intermediate' },
      { name: 'Tailwind CSS', level: 'Intermediate' },
    ],
  },
  {
    category: 'Backend',
    skills: [
      { name: 'Node.js', level: 'Advanced' },
      { name: 'Express.js', level: 'Advanced' },
      { name: 'MongoDB', level: 'Advanced' },
      { name: 'OracleDB', level: 'Intermediate' },
    ],
  },
  {
    category: 'DevOps',
    skills: [
      { name: 'Kubernetes', level: 'Intermediate' },
      { name: 'Docker', level: 'Intermediate' },
      { name: 'AWS', level: 'Intermediate' },
      { name: 'Git', level: 'Advanced' },
    ],
  },
];

const getProficiencyColor = (level) => {
  switch (level) {
    case 'Beginner':
      return 'bg-yellow-400';
    case 'Intermediate':
      return 'bg-blue-400';
    case 'Advanced':
      return 'bg-green-400';
    default:
      return 'bg-gray-400';
  }
};

const TechnologyCard = ({ category, skills }) => (
  <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-bold mb-4">{category}</h3>
    <ul className="space-y-2">
      {skills.map((skill, index) => (
        <li key={index} className="flex justify-between items-center">
          <span>{skill.name}</span>
          <span
            className={`text-sm px-2 py-1 rounded-full ${getProficiencyColor(skill.level)}`}
          >
            {skill.level}
          </span>
        </li>
      ))}
    </ul>
  </div>
);

const Technologies = () => (
  <div className="min-h-screen text-white p-8">
    <header className="mb-12 text-center">
      <h1 className="text-4xl font-bold mb-4">Technologies I Use</h1>
      <p className="text-lg text-gray-400">
        A collection of tools, libraries, and platforms I work with, categorized by usage and proficiency.
      </p>
    </header>
    <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {technologies.map((tech, index) => (
        <TechnologyCard key={index} category={tech.category} skills={tech.skills} />
      ))}
    </main>
    <footer className="mt-12 text-center">
      <h2 className="text-2xl font-bold mb-4">Proficiency Levels</h2>
      <div className="flex justify-center space-x-4">
        <div className="flex items-center">
          <span className="bg-yellow-400 w-4 h-4 rounded-full inline-block mr-2"></span>
          <span>Beginner</span>
        </div>
        <div className="flex items-center">
          <span className="bg-blue-400 w-4 h-4 rounded-full inline-block mr-2"></span>
          <span>Intermediate</span>
        </div>
        <div className="flex items-center">
          <span className="bg-green-400 w-4 h-4 rounded-full inline-block mr-2"></span>
          <span>Advanced</span>
        </div>
      </div>
    </footer>
  </div>
);

export default Technologies;
