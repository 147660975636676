// src/components/Header.js
import React from 'react';

const SidebarElement = ({
    headerText = '',
    bodyText = '',
    isActive = false,
    onClick
}) => {

    const headerClass = `font-bold ${isActive ? 'text-white': 'text-gray'} mb-1`;
    const bodyClass = `${isActive ? 'text-gray-200': 'text-gray-400'} text-sm`;

    return <div onClick={onClick} className="cursor-pointer hover:scale-105 hover:shadow-lg transition-transform duration-500 ease-in-out">
        <h3 className={headerClass}>{headerText}</h3>
        <p className={bodyClass}>{bodyText}</p>
    </div>;
};

export default SidebarElement;