import React from "react";
import { LINKED_IN_RECOMMENDATIONS_LINK } from "../utils/constants";

import recommendation1 from "../assets/Images/recommendation1.png";
import recommendation2 from "../assets/Images/recommendation2.png";
import recommendation3 from "../assets/Images/recommendation3.png";
import recommendation4 from "../assets/Images/recommendation4.png";
import recommendation5 from "../assets/Images/recommendation5.png";

const LINKED_IN_RECCOMENDATION_IMAGES = [recommendation1, recommendation2, recommendation3, recommendation4, recommendation5 ];

const LinkedInRecommendations = () => {
    return (
        <section className="bg-black text-white">
            <div class="max-w-4xl mx-auto text-center">
                <div class="flex flex-col max-h-96 overflow-y-scroll py-2 custom-scrollbar">
                    {
                        LINKED_IN_RECCOMENDATION_IMAGES.map(recommendationImg => <img src={recommendationImg} alt="recommendation" className="w-100 mb-4"/>)
                    }
                </div>
                <a href={LINKED_IN_RECOMMENDATIONS_LINK} target="_blank" className="text-blue-500 underline mt-4 block">View all recommendations on LinkedIn</a>
            </div>
        </section>
    );    
}

export default LinkedInRecommendations;