// src/components/Header.js
import React from 'react';

const Header = () => {
  return (
    <header className="flex justify-between items-center px-8 py-4 bg-black text-white">
      <div className="text-2xl font-bold">Ravinder Rikhi.com</div>
      <nav className="space-x-6"/>
      <button className="px-4 py-2 border rounded-full" onClick={() => window.location = 'mailto:rikhi.ravinder@gmail.com'}>Email Me</button>
    </header>
  );
};

export default Header;